<template>
  <loading-dialog :loading="$apollo.loading">
    <base-page-layout :title="retailerName">
      <template v-slot:title>
        <v-row no-gutters align="center" class="py-2">
          <v-col class="d-flex align-center">
            <p class="my-0 mr-4 page-title">{{ retailerName }}</p>
            <v-icon class="mr-1" small :color="retailerActive ? 'success' : 'error'">
              mdi-brightness-1
            </v-icon>
            <span>{{ retailerActive ? 'Ativo' : 'Inativo' }}</span>
          </v-col>
        </v-row>
      </template>
      <v-tabs v-model="tab" grow class="mb-5">
        <v-tab to="dados-do-varejista">Dados do varejista</v-tab>
        <v-tab to="produtos-ativos">Produtos Ativos</v-tab>
        <v-tab to="lojas">Lojas</v-tab>
        <v-tab to="acoes">Ações</v-tab>
      </v-tabs>
      <router-view :retailer="client" :headers-tab.sync="tab" />
    </base-page-layout>
  </loading-dialog>
</template>

<script>
import { QUERY_GET_RETAILER } from '@/modules/retailers/graphql'
export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue'),
    LoadingDialog: () => import('@/components/base/LoadingDialog.vue')
  },
  data: () => ({
    tab: 0,
    retailerId: '',
    clientUnitsData: [],
    unitsData: []
  }),
  computed: {
    retailerName() {
      return this.client ? this.client.fantasy_name : ''
    },
    retailerActive() {
      return this.client ? this.client.active : ''
    }
  },
  apollo: {
    client: {
      query: QUERY_GET_RETAILER,
      fetchPolicy: 'network-only',
      variables() {
        return { client_id: this.retailerId }
      },
      skip() {
        return !this.retailerId
      },
      result({ data }) {
        localStorage.setItem('accounts_retailer', data.client.accounts)
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      }
    }
  },
  mounted() {
    this.retailerId = this.$route.params.id
  }
}
</script>
